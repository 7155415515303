.search-field {
  width: 100% !important;
}

.searchResult {
  padding: 10px;
  /* font-size: 12px; */

  position: absolute;
  top: 70px;
  overflow-y: hidden;
  z-index: 999999;
  margin: 14px 0px;
  background: #fff;
  border-radius: 10px;
  width: 93%;
}

.search-item {
  /* padding-bottom: 10px; */
  font-size: 14px;
  padding-right: 8px;
  padding-left: 5px;
  height: 35px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  display: table-row;
}

.search-item a {
  color: rgb(0, 0, 0);
}

.search-item:hover {
  background-color: #eee;
}
