.added-to-cart-button {
  background: green !important;
  color: white;
}

.status-bar-success {
  background: #198754;
}

.status-bar-failure {
  background: #dc3545;
}

.badge {
  padding-left: 9px;
  padding-right: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}

.label-warning[href],
.badge-warning[href] {
  background-color: #c67605;
}
#lblCartCount {
  font-size: 12px;
  background: #ff0000;
  color: #fff;
  padding: 0 5px;
  vertical-align: top;
  margin-left: -10px;
}

.Toastify__toast-theme--light {
  background: grey !important;
  color: #fff !important;
}

.Toastify__close-button--light {
  color: #fff !important;
  opacity: 0.3 !important;
}

.carousel-indicators {
  margin-bottom: 0rem !important;
}
