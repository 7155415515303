body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  min-height: 100vh;
}
:root {
  scroll-behavior: smooth;
  background: #fff;
}
html {
  background: #e4e4e4;
}
h4 {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 12px;
  color: #000;
}
.pt-24 {
  padding-top: 24px;
}
.pb-24 {
  padding-bottom: 24px;
}
a {
  text-decoration: none;
}
h4 a {
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  float: right;
  margin-right: 16px;
  color: #ff007a;
}
button {
  min-width: 120px;
  font-size: 16px !important;
}

.btn-group button {
  min-width: auto;
}
.btn-toolbar {
  justify-content: end;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.desk-filter {
  margin-top: 32px;
  background: white;
  border: 1px solid #e4e4e4;
  padding: 12px 30px;
  border-radius: 8px;
}
.desk-filter h4 {
  margin-top: 16px;
  text-transform: uppercase;
  font-size: 14px;
}
.desk-filter .form-check {
  font-size: 14px;
  margin-bottom: 8px;
}
.bg-dark {
  background-color: #ea5d00 !important;
}
.bg-desk {
  background: #f5f5f5;
  height: calc(100vh - 72px);
}
.custom-card {
  text-align: center;
  box-shadow: 0px 3px 7px #c0c0c026;
  border: 1px solid #efefef;
  border-radius: 16px;
}
.custom-card .gift-img {
  text-align: center;
  margin: 14px 10px;
  width: auto;
}

.gift-details {
  background: #ebf0f5;
  min-height: calc(100vh - 72px);
}
.custom-card .card-body {
  padding: 0px;
}
.desk-list {
  margin-top: 32px;
}
.custom-card button {
  width: 100%;
  height: 48px;
  border-radius: 0 0 10px 10px;
  background: #ffd629;
  border: none;
  color: #303030;
  font-weight: 600;
}
.home-ham {
  border: none;
  font-size: 16px;
}
.button-increment {
  max-width: 160px;
}
.remove-btn {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  color: #757575;
  position: absolute;
  bottom: 0;
  right: 0;
}
.search-icon {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 16px;
  left: 16px;
}
.cart-row .details-text {
  padding: 6px;
}
.cart-row .details-text h4 {
  font-size: 18px;
  margin-bottom: 6px;
}
.cart-row .details-first {
  margin-bottom: 0px;
  border-radius: 0;
  margin-top: 0;
  box-shadow: none;
  border-bottom: 1px solid #ebf0f4;
  background: transparent;
}
.left-cart {
  margin-top: 24px;
  border-radius: 10px;
  background: #fff;
  padding: 0px;
}
.home-search {
  padding: 8px;
  padding-left: 32px;
  border: none;
  margin: 6px;
  font-size: 14px;
}
.home-badge {
  padding: 8px 18px;
  font-size: 14px;
  border-radius: 50px;
  display: inline-block;
  margin: 12px 4px;
  background: #fff;
  border: 1px solid #e3e3e3;
  color: #303030;
  text-decoration: none;
  font-weight: 500;
  box-shadow: 0px 1px 4px #5f5f5f4a;
}
.horizontal-scroll {
  overflow-x: auto;
  white-space: nowrap;
  padding: 0px;
  gap: 8px 12px;
}

.scroll-hidden {
  overflow-x: scroll;
}
.green-text {
  color: #03c18b;
}
.card-title {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
.card-text {
  color: #000;
}
.horizontal-scroll::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.banner-img img {
  margin: 10px 16px 16px 0;
  margin-right: 12px;
}
.shadow-img {
  margin: 0px auto 20px;
  opacity: 0.8;
  min-width: 190px;
}
.card-scroll a {
  float: left;
  margin-right: 10px;
}
.bottom-nav {
}

.bottom-nav .nav-link {
  width: 100%;
  border-radius: 50px;
  display: inline-block;
  max-width: 36px;
  height: 36px;
  text-align: center;
  padding: 5px;
}
.navbar-brand {
  margin: 0 10px;
  float: left;
}
.navbar-brand p {
  font-size: 13px;
  letter-spacing: -0.2px;
  margin-bottom: 0;
}
.listing-card {
  border-bottom: 1px solid #e9e9e9;
  background: #fff;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
}
.listing-card .card-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}
.listing-card .gift-img {
  max-width: 150px;
  margin-right: 10px;
}
.listing-card small {
  font-size: 12px;
  display: inline-block;
  line-height: 16px;
  opacity: 0.6;
}
.filter-btn {
  position: fixed;
  bottom: 80px;
  right: 20px;
  border-radius: 50px;
}
.list-group-item {
  padding: 16px;
  padding-left: 56px;
  border: none;
  border-bottom: 1px solid #e8e8e8;
}
.list-group-item h6 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
}
.list-group-item p {
  font-size: 14px;
  opacity: 0.6;
  margin-bottom: 0px;
}
.list-group-item svg {
  position: absolute;
  left: 16px;
  top: 20px;
  width: 24px;
  font-size: 20px;
  opacity: 0.6;
}
.cart-icon {
  font-size: 20px;
  color: #fff;
  padding: 10px;
}
.bg-light {
  background-color: #fff !important;
  box-shadow: 0px -4px 7px #000;
}
.bg-light .back-icon {
  color: #000;
}
.bg-light h5 {
  color: #000 !important;
}
.back-nav {
  font-size: 20px;
  color: #fff;
}
.back-icon {
  font-size: 20px;
  color: #fff;
  padding: 10px;
  float: left;
}
.details-card {
  padding: 16px;
}
.desc {
  font-size: 14px;
  opacity: 0.6;
}

.footer-action button {
  min-width: 124px;
  height: 56px;
  font-weight: 600;
  border-radius: 0px;
  border: none;
}
.footer-action input {
  border-radius: 0px;
  border: none;
}
.input-group > .form-control:focus {
  outline: none;
  box-shadow: none;
}
.input-group > .form-control:focus .paisa {
  display: none;
}
.footer-action .input-group-text {
  background: none;
  border: none;
  padding: 0px 0 0px 20px;
  font-size: 24px;
}

.add-help span {
  background: #fff;
  padding: 8px 16px;
  border: 1px solid #efefef;
  border-radius: 10px;
  margin-right: 8px;
  box-shadow: 0px 0px 3px #cccccc;
  font-weight: 600;
  font-size: 14px;
}
.add-help::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.giftcard-link {
  text-decoration: none;
  color: #303030;
}
.full-model .modal-dialog {
  margin: -1px;
  border-radius: 0;
}
.full-model .modal-dialog .modal-content {
  border-radius: 0px;
  height: 100vh;
}
.modal-footer-action .btn-primary {
  width: 50%;
}
.modal-footer-action .btn-secondary {
  width: 45%;
}
.filter-details .col-md-3 {
  width: 40%;
  float: left;
}
.filter-details .col-md-9 {
  width: 60%;
  float: left;
}
.filter-details .form-check {
  margin-bottom: 16px;
}
.b-24 {
  bottom: 24px !important;
}

.checkout-bottom {
  box-shadow: 0 -2px 10px 0 rgb(0 0 0 / 10%);
  padding: 24px;
  margin-top: 40px;
  text-align: right;
  background: #fff;
  z-index: 9999;
  border-radius: 0 0 10px 10px;
}
.checkout-bottom button {
  height: 48px;
  text-align: right;
  font-weight: 600;
}
.checkout-bottom button span {
  float: left;
}
.alert-success p {
  margin-bottom: 0px;
  color: #303030;
  font-weight: 500;
}
.alert-success {
  background: #dafaf0;
  border: 1px solid #07966b;
  font-size: 13px;
  font-weight: bold;
  color: #087756;
  margin-bottom: 10px;
  padding: 8px 16px;
}
.order-subheader {
  display: flex;
  justify-content: space-between;
}
.order-status-p {
  padding: 12px;
}
a:hover .listing-card {
  color: initial;
  box-shadow: 0px 3px 2px #dadada;
}
.order-status-p p {
  font-size: 12px;
  margin-bottom: 4px;
  text-align: right;
}
.order-subheader .home-search {
  margin: 0px;
  margin-top: 16px;
}
.order-subheader .search-icon {
  top: 27px;
  left: 10px;
}
.order-subheader div:first-child {
  width: 71%;
}
.order-subheader div:last-child {
  margin: 10px 0px;
}
.order-subheader button {
  height: 44px;
  padding: 6px 16px;
}
.list-group a {
  text-decoration: none;
}
.order-status {
  margin: 16px;
  border-bottom: 1px solid #e9e9e9;
}
.order-status p.success {
  color: #087756;
  font-weight: 600;
}
.order-id {
  justify-content: space-between;
  margin: 16px;
  border-bottom: 1px solid #e9e9e9;
}

.order-help {
  justify-content: center;
  margin: 16px;
  border-bottom: 1px solid #e9e9e9;
  border-top: 1px solid #e9e9e9;
  padding-top: 16px;
}
.status-bar {
  display: flex;
  display: list-item;
  text-align: center;
  padding: 1rem 1rem;
  width: 100%;
  border-radius: 10px;
}

.status-bar h4 {
  color: #fff;

  font-size: 20px;
}
.status-bar .icon {
  color: #fff;
  font-size: 64px;
  margin-bottom: 10px;
}
.profile-details-wrapper {
  padding: 24px;
}

.profile-summary-wrapper .list-group-item {
  padding: 16px 0;
  border: none;
  border-bottom: 1px solid #efefef;
}
.profile-summary-wrapper .list-group-item h6 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
.profile-summary-wrapper .list-group-item svg {
  right: 0;
  left: auto;
  opacity: 0.5;
}
.profile-summary-wrapper .list-group-item a {
  right: 0;
  left: auto;
  position: absolute;
  top: 24px;
}

.otp-verify {
  padding: 24px;
}
.otp-verify input {
  height: 56px;
  border: 1px solid #000;
  border-radius: 10px;
  width: 66px;
  margin: 10px 10px 24px 0;
}
.copywrites {
  position: absolute;
  bottom: 0;
}
.mobile-view {
  display: none;
}
.success-img {
  max-width: 100px;
}
a.btn-primary {
  font-size: 16px;
}
.btn-sm {
  font-size: 14px !important;
}
.cart-icon {
  position: absolute;
  right: 0px;
  top: 0px;
}
.search-form {
  width: 80%;
}
.desktop-view {
  display: block;
}
.home-badge {
  font-size: 12px;
}
.w-80 {
  min-width: 500px;
}
.cart-wrapper {
  max-width: 800px;
}
.btn-group .btn {
  padding: 1px 7px !important;
  margin-top: 10px;
}
.container-md {
  max-width: 720px !important;
}
.desktop-wrapper {
  max-width: 600px;
  margin: 0px auto;
  background: #fff;
  border-radius: 10px;
}
.add-address .modal-header .btn-close {
  display: none;
}
.add-address label {
  font-size: 13px;
  margin-bottom: 2px;
}
.add-address .form-control,
.add-address select {
  font-size: 14px;
}
.cart-row .pay-cart-text h4 {
  font-size: 15px;
}

.address-label {
  display: block;
  margin-bottom: 32px;
  margin-left: 16px;
}
.address-label h6 {
  font-size: 15px;
}
.address-label small {
  opacity: 0.7;
}
.address-label h6 .status {
  font-size: 11px;
  background: #ebf0f4;
  text-transform: uppercase;
  padding: 5px 7px;
  border-radius: 3px;
  font-weight: 700;
  margin-left: 5px;
}
.address-label h6 .edit {
  font-size: 14px;
  text-transform: uppercase;
  padding: 5px 10px;
  border-radius: 3px;
  font-weight: 700;
  margin-bottom: 10px;
  float: right;
}
.price-details {
  padding: 16px 24px;
  background: #fff;
  box-shadow: 0px 0px 10px #e3e3e3;
  border-radius: 10px;
  margin: 10px;
  margin-top: 24px;
}
.price-details label {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  border-bottom: 1px solid #e8e8e8;
  display: block;
  margin-bottom: 10px;
  padding-bottom: 16px;
  margin-top: 10px;
  color: #303030;
}
.price-details h4 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
}
.price-details h4.total {
  border-top: 1px dashed #c0c0c0;
  border-bottom: 1px dashed #c0c0c0;
  padding: 24px 0;
}
.price-details h4 span {
  font-size: 16px;
  font-weight: 700;
  float: right;
}
.cart-wrapper {
  padding: 24px;
}
.add-cart {
  display: block;
  width: 180px;
  padding: 10px 16px;
  border-radius: 8px;
  font-weight: 600;
}

.footer-action {
  background: #ffffff;
  display: flex;
  max-width: 540px;
  position: relative;
  border: 1px solid #dbdbdb;
  box-shadow: 0px 6px 10px #e0e0e0;
  bottom: 0;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
}
.add-help {
  background: transparent;
  padding: 12px 0;
  position: relative;
  max-width: 540px;
  overflow-x: auto;
  white-space: nowrap;
}
.profile-menu {
  position: absolute;
  right: 23vh;
  top: 7px;
  background: #fff;
  /* padding: 3px; */
  height: 36px;
  width: 36px;
  text-align: center;
  font-size: 21px;
  border-radius: 40px;
}
.details-first {
  background: #fff;
  box-shadow: 0px 0px 10px #e3e3e3;
  border-radius: 10px;
  padding: 20px;
  margin-top: 16px;
  margin-bottom: 24px;
}
.w-40 {
  width: 40%;
}
.details-text {
  padding: 24px;
}
.tags.discount {
  font-size: 11px;
  display: inline-block;
  background: #13ad68;
  color: #fff;
  padding: 3px 10px;
  border-radius: 40px;
  margin-left: 10px;
  font-weight: bold;
}
.tags.available {
  font-size: 11px;
  display: inline-block;
  background: #494949;
  color: #fff;
  padding: 3px 10px;
  border-radius: 40px;
  margin-left: 6px;
  font-weight: bold;
}
.nav-tabs .nav-link.active {
  color: #0d6efd;
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
  font-weight: 600;
  border: none;
  border-bottom: 2px solid;
}
.nav-tabs .nav-link {
  border: none;
  color: #303030;
  font-weight: 600;
}
.safe {
  opacity: 0.6;
  margin-left: 10px;
  font-size: 14px;
}
.safe svg {
  font-size: 24px;
}
.bottom-nav .btn-group .btn {
  padding: 6px 36px !important;
  margin-top: 0px;
  font-weight: 500;
  font-size: 18px;
}
.bottom-nav {
  position: relative !important;
}
.bottom-nav .btn-group-lg {
  width: auto;
}
.payment-cart {
  margin-top: 24px;
}
.accordion-item .accordion-button {
  font-size: 16px;
  font-weight: 600;
  border-radius: 10px;
}
.accordion-item {
  background: #fff;
  margin-bottom: 10px;
  border-radius: 8px !important;
}
.accordion-button::after {
  display: none;
}
.accordion-button:not(.collapsed) {
  border-radius: 8px 8px 0 0;
}
.payment-cart .accordion-header span {
  background: #ebf0f4;
  padding: 6px 12px;
  border-radius: 43px;
  height: 32px;
  width: 32px;
  color: #000;
  margin-right: 16px;
}
.accordion-button:not(.collapsed) {
  background-color: var(--bs-accordion-active-color);
  color: #fff;
}
.accordion-button:not(.collapsed) span {
  color: var(--bs-accordion-active-color);
}
.footer {
  background: #fafafa;
  padding-top: 3rem;
  border-top: 1px solid #efefef;
}
.top-nav {
  background-color: #281096 !important;
  border: none !important;
  height: 36px;
}
.top-nav a {
  color: white !important;
  padding: 0;
  font-size: 12px;
}
.top-nav a:hover {
  color: white !important;
  opacity: 0.8;
}
.footer h5 {
  font-size: 14px;
}
.footer p {
  font-size: 12px;
}
.footer .text-muted {
  font-size: 13px;
}
.login-wrapper {
  max-width: 800px;
  margin-top: 5rem;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 0px 10px #e6e6e6;
}
.login-wrapper .left {
  padding: 3rem;
  background: #0d6efd;
  border-radius: 20px 0 0 20px;
}
.login-wrapper .left,
.login-wrapper .left h4 {
  color: #fff;
}
.login-wrapper .right {
  padding: 3rem;
}
.login-wrapper img {
  max-width: 303px;
}
.error-img {
  max-width: 400px;
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .bottom-nav .btn-group-lg {
    width: 47%;
  }
  .login-wrapper {
    margin-top: 0px;
  }
  .login-wrapper .left {
    border-radius: 0px;
    padding: 2rem 2rem 0px;
  }
  .login-wrapper .left h1 {
    display: none;
  }
  .bottom-nav {
    position: fixed !important;
    left: 0;
    bottom: 0;
    background: white;
    box-shadow: 0 4px 10px #8b8b8b;
    width: 100%;
    padding: 10px 16px;
    max-width: 425px;
  }
  .cart-wrapper {
    padding: 0;
  }
  .add-help {
    background: #f6f6f6;
    padding: 24px 16px;
    position: fixed;
    width: 100%;
    bottom: 66px;
    overflow-x: auto;
    white-space: nowrap;
    left: 0;
  }
  .footer-action {
    position: fixed;
    width: 100%;
    border: none;
    margin: 0;
    left: 0;
    border-radius: 0;
    box-shadow: 0px -6px 10px #e0e0e0;
  }

  .w-80 {
    min-width: 100%;
  }
  .search-form {
    width: 100%;
  }
  .mobile-view {
    display: block;
  }
  .cart-icon {
    position: relative;
    right: 0px;
    top: 0;
  }
  .desktop-view {
    display: none !important;
  }
  .scroll-hidden .container {
    padding-right: 0px;
  }
  .checkout-bottom {
    padding: 10px;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0px;
    border-radius: 0px;
  }

  .desktop-wrapper {
    margin: auto;
  }
  .horizontal-scroll {
    gap: 8px 8px;
  }
  .bg-desk {
    height: auto;
    background: #fff;
  }
  .desk-list {
    margin-top: 0px;
  }
  .details-first {
    display: block !important;
    margin-top: 0;
  }
  .details-card {
    padding: 0px;
    padding-bottom: 164px;
    padding-top: 16px;
  }

  .details-text {
    padding: 0;
    width: 100% !important;
  }
  .w-40 {
    width: 100%;
  }
  .text-start {
    width: 30%;
    float: left;
    margin-right: 16px;
    min-height: 110px;
  }
  .left-cart {
    border-radius: 0px;
  }
  .cart-row .details-first {
    border-bottom: 10px solid #ebf0f4;
  }
  .button-increment {
    max-width: 120px;
    margin-top: 20px;
    margin-bottom: 0 !important;
  }
  .cart-row .details-first .position-relative {
    border-top: 1px solid #efefef;
    margin-top: 10px;
    position: relative !important;
    align-items: flex-end;
    display: flex;
    width: 100%;
  }
  .price-details {
    margin: -10px;
    margin-top: 16px;
    border-radius: 0px;
    margin-bottom: 16px;
    border: none;
    box-shadow: none;
  }
  .total-mobile {
    display: inline-block;
    float: left;
    font-size: 14px;
    color: #535353;
  }
  .total-mobile span {
    text-align: left;
    font-size: 18px;
    display: block;
    margin-top: 3px;
    color: #303030;
  }
  .order-status-p {
    display: none;
  }
  .status-bar {
    border-radius: 0px;
  }
  .profile-summary-wrapper {
    padding: 24px;
  }
  .profile-summary-wrapper .gift-details {
    background: transparent;
  }
}
