.footer .footer-bottom .module-body ul li a:hover,
.footer .footer-bottom .module-body ul li a:focus {
  color: #12cca7;
}


.footer .links-social .social-icons a:hover,
.footer .links-social .social-icons a:focus,
.footer .links-social .social-icons a.active {
  background: #12cca7;
}

.footer .contact-information .media .icon .fa-stack-2x {
  color: #12cca7;
}
.footer .contact-information .media .media-body a:hover,
.footer .contact-information .media .media-body a:focus {
  color: #12cca7;
}
.footer .footer-bottom .module-body ul li a:hover,
.footer .footer-bottom .module-body ul li a:focus {
  color: #12cca7;
}

.footer .links-social {
  border-top: 1px solid #e5e5e5;
  font-size: 12px;
  line-height: 18px;
  color: #777777;
  margin-bottom: 70px;
}

.footer-app img {
  width: 100%;
  padding-top: 21px;
  }
  
  .footer-app-buttons img {
  width:50%;
  padding-top: 15px;
  }
  
  .footer .links-social .contact-info .footer-logo {
    margin-top: 10px;
  }
  .footer .links-social .contact-info .about-us {
    margin-bottom: 20px;
  }
  .footer .links-social .contact-info .social-icons a {
    color: #888888;
    font-size: 16px;
    -webkit-transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
    transition: all 0.2s linear 0s;
    width: 2em;
    height: 2em;
    display: inline-block;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    text-align: center;
  }
  .footer .links-social .contact-info .social-icons a:hover,
  .footer .links-social .contact-info .social-icons a:focus,
  .footer .links-social .contact-info .social-icons a.active {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    color: #fff;
  }
  .footer .links-social .contact-info .social-icons a .icon {
    line-height: 2em;
  }
  .footer .module-heading {
    font-size: 16px;
    color: #555555;
  }
  .footer .contact-timing .table tbody tr {
    border-bottom: 1px solid #e1e1e1;
  }
  .footer .contact-timing .table tbody tr td {
    border-top: none;
    font-size: 12px;
    line-height: 36px;
    padding: 0px !important;
  }
  .footer .contact-timing .contact-number {
  
  }
  .footer .contact-information .media .media-body a {
    color: #777777;
  }
  .footer .footer-bottom {
    padding-top: 30px;
    padding-bottom:30px;
    font-size:14px;
    background:#f6f6f6;
    margin-top:40px;
  
  }
  
  .footer .copyright-bar.white-bg {background:#fff}
  
  .footer .module-title {
    font-size:16px;
    font-weight:600;
    letter-spacing:1px;
    margin-bottom:15px
    }
  
  .footer .footer-bottom .module-heading {
    font-size: 12px;
    color: #333;
    text-transform:uppercase
  }
  .footer .footer-bottom .module-body ul li {
  
  }
  
  .toggle-footer {color:#abafb1}
  .toggle-footer a {margin-left:0px!important}
  .toggle-footer a:before {display:none}
  .toggle-footer i{background:#f6dc5b; border-radius:999px; color:#2e353b}
  
  .footer .footer-bottom .module-body ul li:last-child{border:none}
  
  .footer .footer-bottom .module-body ul li a {
    font-size: 13px;
    line-height: 30px;
    color: #777;
    position: relative;
    margin-left: 15px;
    display: block;
  }
  .footer .footer-bottom .module-body ul li a:before {
    content: "\f111";
    font-family: FontAwesome;
    position: absolute;
    margin-left: -13px;
    font-size: 4px;
    color:#999
  }